<template>
  <div class="home">
	
    <Menu></Menu>
  	<router-view/>
  	<Footer></Footer>
  </div>
</template>

<script>
import Menu from '@/components/menu/menu.vue';//头部
import Footer from '@/components/footer/footer.vue';//底部
export default {
  name: 'Index',
  components: {
    Footer,Menu
  }
}
</script>
<style>
	@import url('../assets/css/common/common_web.css');
</style>
