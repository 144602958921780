import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import cookie from 'vue-cookie';
import store from '../store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect:'/index',
    children:[
      {
		    path: '/index',
		    name: 'Index',
		    component: () => import( '../views/index/index.vue')
		  },
		  {
		    path: '/financing',
		    name: 'Financing',
		    component: () => import( '../views/financing/financing.vue')
		  },
		  {
		    path: '/dispole',
		    name: 'Dispole',
		    component: () => import( '../views/dispole/dispole.vue')
		  },
		  {
		    path: '/manage',
		    name: 'Manage',
		    component: () => import( '../views/manage/manage.vue')
		  },
		  {
		    path: '/about',
		    name: 'About',
		    component: () => import( '../views/about/about.vue')
		  },
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/public/Login.vue'),
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/public/Register.vue'),
  },
  {
    path: '/forgotPwd',
    name: 'ForgotPwd',
    component: () => import(/* webpackChunkName: "about" */ '../views/public/ForgotPwd.vue'),
  },
  
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
