<template>
	<div class="Q_step_box">
		<div class="step_item" v-for="(item,index) in setpArr" :key="index">
			<div class="step_item_l">
				<img :src="index+1<=current?item.activeImg:item.img" />
				<div class="title" :class="{ active: index+1<=current }">{{ item.title }}</div>
			</div>
			<div class="step_line" :class="{ active: index+1<current }">
				<div v-for="item in 8"></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
       name:'qsetp',
       props:{
       		current:{
       			type:Number,
       			default:0
       		},
       		setpArr:{
       			type:Array,
       			default:[]
       		}
       	},
       data() {
       	 return {
       	 	
       	 }
       }
	}
</script>

<style scoped="scoped" lang="scss">
	.Q_step_box {
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.Q_step_box .step_item{
		display: flex;
		align-items: baseline;
	}
	.Q_step_box .step_item .title{
		margin-top: 18px;
		color: #101010;
	}
	.Q_step_box .step_item .step_item_l{
		text-align: center;
	}
	.Q_step_box .step_item:last-child .step_line{
		display: none;
	}
	.Q_step_box .step_item .step_line {
		display: flex;
		margin: 0 20px;
	}
	.Q_step_box .step_item .step_line div {
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background-color: #666666;
		margin-right: 10px;
	}
	.Q_step_box .step_item .active {
		color: #101010;
	}
	.Q_step_box .step_item .active div {
		background-color: #016FF1;
	}
	.Q_step_box .step_item .step_line div:last-child {
		margin: 0;
	}
</style>