<template>
  <div id="app">
	  <router-view v-if="isRouterAlive"></router-view>
    
  </div>
</template>
<script>
export default {
  provide (){
    return {
      reload : this.reload
    }
  },
  data() {
    return{
      isRouterAlive : true
    };
  },
  created(){
    
  },
  methods:{
    reload(){
      this.isRouterAlive = false;
      this.$nextTick(function(){
        this.isRouterAlive = true
      })
    },
    
  },
};
</script>
<style lang="scss" >
@import url('./assets/css/common/common.css');
</style>
