<template>
	<div>
		<span style="cursor: pointer;display: block;">{{ BtnStatus?'获取验证码':`重新获取(${countDownTime}s)` }}</span>
	</div>
</template>

<script>
	/*
       作者：qujianhusir@163.com
        时间：2021-04-23
        描述：倒计时组件
          time 倒计时时间
    */
	export default {
		name:'countDown',
		props:{
//		  倒计时时间
		  time:{
		  	type:Number,
		  	default:60
		  },
		  getCodeEvent:{
		  	type:Function,
		  	default:null
		  }
		},
		name:'countDown',
		data() {
			return {
				BtnStatus:true,
				countDownTime:60,
				timer:null,
			}
		},
		methods:{
			sonEvent() {
				if(this.BtnStatus){
					this.countDownTime = this.time;
					this.getCodeEvent();//倒计时方法
				}
			},
			//倒计时方法
			codeTimer() {
				var _this = this;
				if(this.BtnStatus) {
					this.BtnStatus = false;
					this.timer = setInterval(() => {
						_this.countDownTime--;
						if(_this.countDownTime < 1) {
							clearInterval(this.timer);
							_this.countDownTime = _this.time;
							_this.BtnStatus = true;
						} else {
							_this.codeTimer();
						}
					}, 1000)
				}
			},
			//清除定时器
			clearTimer() {
				clearInterval(this.timer);
			}
		}
	}
</script>

<style>
</style>