import Vue from 'vue'
import axios from "axios";
import qs from "qs";
import cookie from 'vue-cookie';
axios.defaults.baseURL = process.env.VUE_APP_BASEURL;
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
//axios.defaults.headers.post["Content-Type"] =
//	"application/x-www-form-urlencoded;charset=UTF-8";
/********************设置一个响应拦截器*************************/
axios.interceptors.response.use(
	function(response) {
		if (response.data && response.data.code === '1103') {  //如果前端拿到状态码为1103，token失效(就清除token信息并跳转到登录页面)
		    store.state.isLogin = false ;
		    cookie.set('Rtoken','',{expires:0});
		    cookie.set('RuserInfo','',{expires:0});
		    Vue.prototype.$message.error('登录已过期，请重新登录');
		    router.push({ path: '/login' });
		}
		return response.data;
	},
	function(error) {
		return Promise.reject(error);
	}
);
/********************设置一个请求拦截器*************************/
axios.interceptors.request.use(
	function(config) {
		if (cookie.get('Rtoken')) {
	      config.headers['X-finance-Access-Token'] = cookie.get('Rtoken');
	    }
		var contentType = config.headers["Content-Type"];
		if(config.method === "post" && contentType != "multipart/form-data") {
			config.data = qs.stringify(config.data);
		}
		return config;
	},
	function(error) {
		return Promise.reject(error);
	}
);

//上传头像
export const uploadPic = param => {
	return axios.post("/book-rest/api/uploadPic", param, {
		headers: {
			"Content-Type": "multipart/form-data"
		}
	});
};

/************************用于mixins接口请求封装请求方法************************/
export function postAction(url, param) {
  return axios({
    url: url,
    method: 'post',
    data: param
  })
}

export function getAction(url, param) {
  return axios({
    url: url,
    method: 'get',
    params: param
  })
}
/********************************************************************/

//公共验证码接口
export const sendSmsCode = param => {
	return axios.post("common/sendSmsCode", param, {});
};

//获取省市区接口
export const selectAreaByPid = param => {
	return axios.post("common/selectAreaByPid", param, {});
};
// 生成随机图片验证码
export const getCodeImg = param => {
	return axios.post("loginAndRegister/createCaptcha", param, {});
};
// 发送短信验证码1  注册  
export const sendCodeReg = param => {
	return axios.post("/loginAndRegister/sendCodeOfRegister", param, {});
};
// 发送短信验证码2  登录  
export const sendCodeLog = param => {
	return axios.post("loginAndRegister/sendCodeOfLogin", param, {});
};
// 发送短信验证码3  忘记密码  
export const sendCodeForget = param => {
	return axios.post("loginAndRegister/sendCodeOfForget", param, {});
};
//用户注册 
export const register = param => {
	return axios.post("/loginAndRegister/finance/registering", param, {});
};
// 短信登陆
export const mesLogin = param => {
	return axios.post("loginAndRegister/loginingForSms", param, {});
};
//密码登录
export const pwdLogin = param => {
	return axios.post("loginAndRegister/logining", param, {});
	
};
// 用户退出登录
export const closeLogin = param => {
	return axios.post("loginAndRegister/logout", param, {});
};
// 忘记密码
export const forgetPwd = param => {
	return axios.post("loginAndRegister/forgetPwd", param, {});
};

//计算器
export const calculator = param => {
	return axios.post("intention/calculator", param, {});
};

//新增融资意向
export const addIntention = param => {
	return axios.post("intention/addIntention", param, {});
};








