import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';
import  qs from 'qs';
import cookie from 'vue-cookie';
// 引入element  Message
import {Message,Table,Select,Option,DatePicker,TableColumn,Form,FormItem,Input,Button,Image,CheckboxGroup,Checkbox,Dropdown,DropdownMenu,DropdownItem} from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(Form);
Vue.use(Input);
Vue.use(Button);
Vue.use(Image);
Vue.use(FormItem);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(DatePicker);
Vue.use(Select);
Vue.use(Option);

Vue.config.productionTip = false;

import Qcomponents from '@/utils/components.js';//自定义全局组件注入
Vue.use(Qcomponents);


import commonEvent from '@/utils/bussEvent';
Vue.prototype.commonEvent = commonEvent;


//全局挂载
//QS是axios库中带的，不需要我们再npm安装一个
Vue.prototype.$axios = axios;
Vue.prototype.qs = qs;//axios的插件qs 转换参数格式
Vue.prototype.$cookie = cookie;// 配置cookie
axios.defaults.withCredentials=true; // 让ajax携带cookie
Vue.prototype.imgBasUrl2 = 'http://assets-test.obs.cn-north-4.myhuaweicloud.com/pc/static/img/';//后台管理上传的oss文件地址路径

/*QJ*/
 // 引入jQuery
import $ from 'jquery'
// 引用bootstrap
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min'
/**
 * ---xd 
 * 重写Element的Message
 * single默认值true，因为项目需求，默认只弹出一个。可以根据实际需要设置
 */
 const showMessage = Symbol('showMessage');// 为了实现class的私有属性
 class DonMessage{
  success(options,single = true){
    this[showMessage]('success',options,single)
  }
  warning(options,single = true){
    this[showMessage]('warning',options,single)
  }
  info(options,single = true){
    this[showMessage]('info',options,single)
  }
  error(options,single = true){
    this[showMessage]('error',options,single)
  }
  [showMessage](type,options,single){
    if(single){
      // 判断是否已存在Message
      if(document.getElementsByClassName('el-message').length === 0){
        Message[type](options)
      }
    }else{
      Message[type](options)
    }
  }
 }
Vue.prototype.$message = new DonMessage();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
