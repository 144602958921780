import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  isLogin:false,//是否已登录
	  infor:'',//用户信息
	  userphone:'',
  },
  //获取属性状态
  getters:{
   //获取登录状态
   isLogin:state => state.isLogin,
  },
 mutations: {
   //保持登录状态
   userStatus(state,flag){
     state.isLogin = flag;
   },
   
 },
 actions: {
   userLogin({commit},flag){
     commit('userStatus',flag);
   }
 },
  modules: {
  }
})
