/*自定义全局组件的定义
 *  install 中加入vue中
 */
import CountDown from '@/components/common/countDown/countDown.vue';//倒计时组件
import SetpBox from '@/components/common/newStep/qsetp.vue';//进度组件
let components = [
   CountDown,
   SetpBox
]
export default {
    install(Vue, options) {
    	components.forEach(component => {
		    Vue.component(component.name, component);
		});
　　}
}