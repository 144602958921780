<template>
	<div class="shadow-sm bg-white">
		<div class="d-flex flex-column flex-lg-row align-items-center p-2 pt-4 pb-4  mb-2 bg-white widthMin" >
		   <router-link to="/index" class="my-0 mr-lg-auto" >
		     <img src="@/assets/images/web_h5/logo.png" @click="login" />
		   </router-link>
		  <nav class="my-2 my-md-0 mr-md-3 q_nav">
		    <router-link to="/index" class="p-4 p-md-3" :class="this.$route.path=='/index'?'active':'text-black-50'">首页·解决方案</router-link>
		    <router-link to="/financing" class="p-4 p-md-3" :class="this.$route.path=='/financing'?'active':'text-black-50'">智慧融资</router-link>
		    <router-link to="/dispole" class="p-4 p-md-3" :class="this.$route.path=='/dispole'?'active':'text-black-50'">智慧处置</router-link>
		    <router-link to="/manage" class="p-4 p-md-3" :class="this.$route.path=='/manage'?'active':'text-black-50'">智慧管理</router-link>
		    <router-link to="/about" class="p-4 p-md-3" :class="this.$route.path=='/about'?'active':'text-black-50'">关于我们</router-link>
			<button @click="login" v-if="$store.state.isLogin === false">
				<img src="../../assets/images/user.png" />
				登录/注册
			</button>
			<div v-else class="q_dropdown" style="display: inline-block;text-align: center;">
				 <el-dropdown @command="handleCommand">
			      <span class="el-dropdown-link">
			        {{userphone}}<i class="el-icon-arrow-down el-icon--right"></i>
			      </span>
			      <el-dropdown-menu slot="dropdown">
			        <el-dropdown-item command="user">个人中心</el-dropdown-item>
			        <el-dropdown-item command="close">退出登录</el-dropdown-item>
			      </el-dropdown-menu>
			    </el-dropdown>
			</div>
		  </nav>
		 
		</div>
	</div>
</template>

<script>
import {closeLogin} from "@/assets/js/api";
export default {
  components: {
 
  },
  data() {
    return {
      userInfo:'',
	  userphone:''
    }
  },
  created(){
    this.getInfo();
  },
  methods:{
	login(){
	  	this.$router.push({name:'Login'})
	},
    //退出登录
    closelogin () { 
        closeLogin().then((res)=>{
		  if(res.code === "1110"){
		    this.$store.state.isLogin = false ;
		    this.$cookie.set('Rtoken','',{expires:0});
		    this.$cookie.set('RuserInfo','',{expires:0});
		    this.$router.push({name:'Close'});
			this.$message.success('退出成功')
		    setTimeout(()=>{
		      this.$router.push({name:'Index'});
		    },2000)
		  }else{
		    this.$message.error(res.msg)
		  }
        },(err)=>{
          console.log(err)
        })
      
    },
	//
	handleCommand(val){
	  var _this = this;
	  if(val === 'close'){
	    this.closelogin();
	  }else if(val === 'user'){
	  	let userInfo = JSON.parse(this.$cookie.get('RuserInfo'))
        let data = {
        	mobile:userInfo.phone,
        	password:userInfo.password,
        }
	    window.open('http://test.hs95.com/login?loginType=5'+'&lData='+JSON.stringify(data))
	  }
	},
	//是否登录
	getInfo(){
	  if(this.$cookie.get('Rtoken')){
		this.$store.state.isLogin = true;
	    this.$store.state.infor = JSON.parse(this.$cookie.get('RuserInfo'));
		
	    this.phoneNumFilter ();
	    return ;
	  }else{
		this.$store.state.isLogin = false
	  }
	},
	//隐藏手机号中间几位
	phoneNumFilter () {
	  // 1字符串转化成数组
	  var _this = this ;
	  let phoneArr = [... this.$store.state.infor.phone];
	  // 2.将数组中的4-7位变成*
	  var userphone = '';
	  phoneArr.map((res, index) => {
	    if (index > 2 && index < 7) {
	      userphone += '*';
	    } else {
	      userphone += res;
	    }
	  });
	  return this.userphone = userphone;
	}
  },
  updated(){
    this.getInfo();
  }
}
</script>

<style>
.q_nav button {margin: 9px 0 0 40px;width: 120px;height: 36px;line-height: 36px;background: rgba(0, 0, 0, 0);border-radius: 18px;border: 1px solid #CCCCCC;font-size: 14px;}
.q_nav .q_dropdown {margin: 9px 0 0 40px;width: 120px;height: 36px;line-height: 36px;background: rgba(0, 0, 0, 0);border-radius: 18px;border: 1px solid #CCCCCC;font-size: 14px;}
.q_nav button img {margin-right: 2px;}
</style>